import { cn } from "../lib//utils"
import { Icons } from "./icons"

export default function Menu() {
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6")}
    >
      <a href="/" className="relative z-20 flex items-center text-xl font-bold">
        <Icons.DEPAGE_LOGO className="h-[40px] w-[40px] mr-2" />
        depage
      </a>
      <a
        href="/"
        className="text-sm font-medium transition-colors hover:text-primary"
      >
        Passages
      </a>
    </nav>
  )
}