import React, { useState, useEffect } from 'react';
import './App.css';
import Menu from './components/menu';
import AuthenticationPage from './pages/Authentication';
import PassageTable from './components/passageTable';
import client from './client';
import UserNav from './components/user-nav';
import Passages from './pages/Passages';
import Passage from './pages/Passage.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import { PostHogProvider, usePostHog} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}


export async function getSearch(passageId: string) {
  const passage = await client.getPassage(passageId)
  return passage
}

export async function loader(params: any) {
  if (params.params.passage_id) {
    const passage = await getSearch(params.params.passage_id) 
    return { passage } 
  }
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Passages />,
  },
  {
    path: "/passages",
    element: <Passages />
  },
  {
    path: "/passages/:passage_id",
    element: <Passage />,
    loader: loader
  }
]);

declare global {
  interface Window {
    currentUser: any // TODO - create a type for User
    clickedDelete: boolean
  }
}


function App() {
  const posthog = usePostHog();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const setCurrentUser = (userData: any) => {
    window.currentUser = userData
  }

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        console.log('called getCurrent user')
        const response = await client.getCurrentUser()
        if (response.status === 200) {
          setLoggedIn(true)
          setCurrentUser(response.data)
          posthog?.identify(response.data.id, {})
        }
      } catch (error) {
        console.error(error)
      }
    }
    getCurrentUser()
  }, [])

  return (
    <>
      <PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_API_KEY}
        options={options}
        >
          <CookiesProvider>
          { loggedIn ? (
            <>
              <div className="hidden flex-col md:flex">
                <div className="border-b">
                  <div className="flex h-16 items-center px-4">
                    <Menu />
                    <div className="ml-auto flex items-center space-x-4">
                      <UserNav />
                    </div>
                  </div>
                </div>
                <RouterProvider router={router} />
              </div>
            </>
          ) : (
            <AuthenticationPage />
          )}
          </CookiesProvider>
        </PostHogProvider>
      </>
  );
}

export default App;
