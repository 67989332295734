import React, { useState, useEffect, useRef, useCallback } from 'react';

// import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { SheetHeader, SheetDescription, SheetTitle } from '../components/ui/sheet';
import useWebsocket, { ReadyState } from 'react-use-websocket'
import client from '../client';
import MarkdownView from 'react-showdown';

export interface MessageObject {
  created_at: string;
  updated_at: string;
  id: string;
  message_thread_id: string;
  openai_message_id: string;
  text: string;
  user_id: string;
}

export interface Message {
  cursor: string;
  data: MessageObject
}

export interface PageInfo {
  has_previous_page: boolean;
  has_next_page: boolean;
  start_cursor: string;
  end_cursor: string;
}

export interface MessageData {
  page: Message[];
  page_info: PageInfo;
  total: number
}

const SOCKET_URL = process.env.REACT_APP_PUBLIC_SOCKET_URL as string

export default function PassageChat({ passageId }: { passageId: string }) {
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebsocket(SOCKET_URL, {
    share: false,
    shouldReconnect: () => true,
    heartbeat: {
      message: JSON.stringify({ message: "ping" }),
      returnMessage: "pong",
      timeout: 55000,
      interval: 15000
    }
  })
  const [messages, setMessages] = useState<Message[]>([]);
  const [threadId, setThreadId] = useState<string>("")
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const [totalMessages, setTotalMessages] = useState<number>(0);
  const [newMessage, setNewMessage] = useState('');
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [disconnectScrollToBottom, setDisconnectScrollToBottom] = useState<boolean>(false);

  useEffect(() => {
    const getMessages = async () => {
      fetchMessages()  
    }
    getMessages()
  }, [])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop } = messagesContainerRef.current;
      console.log(scrollTop);
      if (scrollTop === 0 && pageInfo?.has_next_page) {
        fetchMessages(pageInfo.end_cursor);
      }
    }
  };

  const scrollToTop = () => {
    messagesContainerRef.current?.scrollTo(10, 0);
  }
  
  useEffect(() => {
    if (!disconnectScrollToBottom) {
      scrollToBottom()
    }}, [messages]);

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [pageInfo]);


  const fetchMessages = async (cursor?: string | undefined) => {
    try {
      const response = await client.getMessages(passageId, cursor)
        if (response.status === 200) {
          if (cursor) {
            setDisconnectScrollToBottom(true)
            setMessages((messages) => [...response.data.page.reverse(), ...messages])
            setPageInfo(response.data.page_info)
            setTotalMessages(response.data.total)
            scrollToTop()
          } else {
            setMessages(response.data.page.reverse())
            setPageInfo(response.data.page_info)
            setTotalMessages(response.data.total)
          }
        }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (messages.length > 0) {
      setThreadId(messages[0].data.message_thread_id)
    }
  }, [messages])

  useEffect(() => {
    if (threadId && threadId.length > 0) {
      sendJsonMessage({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "MessageThreadChannel",
          id: threadId
        })
      })
    }
  }, [threadId])
  

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.hasOwnProperty('identifier') && !lastJsonMessage.hasOwnProperty('type')) {
      const objWithMessageProperty = lastJsonMessage as { message: MessageObject }
      setMessages((messages) => [...messages, { cursor: '', data: objWithMessageProperty.message }])
      if (objWithMessageProperty.message.user_id === window.currentUser.id) {
        setShowLoadingMessage(true)
      } else {
        setShowLoadingMessage(false)
      }
    }
  }, [lastJsonMessage])

  useEffect(() => {

  })

  const handleNewMessageChange = (event: React.BaseSyntheticEvent) => {
    setNewMessage(event.target.value);
  };

  const sendMessage = async () => {
    try {
      const response = await client.sendMessage(passageId, newMessage)
      setNewMessage('')

      if (response.status === 200) {
        fetchMessages()
      }
    } catch (e) {
      console.error(e)
    }
  }
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex flex-col h-full">
      <SheetHeader>
        <SheetTitle>Passage Chat</SheetTitle>
        <SheetDescription>Chat with AI about this passage.</SheetDescription>
      </SheetHeader>
      <div className="flex-1 overflow-hidden"> {/* Full height container */}
        <div className="flex flex-col h-full bg-white"> {/* Chat container */}
          <div className="flex-1 overflow-auto p-3 space-y-2" ref={messagesContainerRef}> {/* Messages container */}
            {messages.map((message, i) => {
              if (message.data.user_id === window.currentUser.id) {
                return (
                  <div key={i} className="bg-blue-500 rounded p-2 text-white">
                    <MarkdownView markdown={message.data.text} />
                  </div>
                )
              } else {
                return (
                  <div key={i} className="bg-blue-100 rounded p-2 text-gray-700">
                    <MarkdownView markdown={message.data.text} />
                  </div>
                )
              }
            })}
            {showLoadingMessage && (
              <div className="bg-blue-100 rounded p-2 text-gray-700">
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                  <div className="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                  <div className="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-3 bg-gray-50"> {/* Input container */}
            <div className="flex items-center">
              <textarea
                value={newMessage}
                onChange={handleNewMessageChange}
                placeholder="Write message..."
                className="flex-1 p-2 text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded shadow-sm resize-none"
                rows={3}
              />
              <button 
                onClick={sendMessage}
                className="ml-2 px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded shadow"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
