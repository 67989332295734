import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from "./ui/button"
import { PassageTableToolbar } from './passageTableToolbar';
import { 
  PaginationState,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import client from '../client';
import { DataTableRowActions } from './dataTableRowActions';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table"

export type Passage = {
  id: string;
  name: string;
  summary: string;
  starting_page: number;
  ending_page: number;
  message_thread_id?: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export const columns: ColumnDef<Passage>[] = [
  {
    accessorKey: "name",
    header: "Name"
  },
  {
    accessorKey: "status",
    header: "Status",
  },
  {
    accessorKey: "starting_page", 
    header: "Starting Page"
  },
  {
    accessorKey: "ending_page",
    header: "Ending Page"
  },
  {
    accessorKey: "updated_at",
    header: "Last Updated",
    cell: ({ row }) => {
      const formattedDate = new Date(row.getValue('updated_at')).toLocaleDateString()
      return <div className="font-medium">{formattedDate}</div>
    },
  },
  {
    id:"actions",
    header: "Actions",
    cell: ({ row }) => {
      const passage = row.original
      return <DataTableRowActions passage={passage} />
    }
  }
]

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  pagination: React.ReactElement,
  search: (searchTerm: string) => void
}

export function DataTable<TData, TValue>({
  columns,
  data,
  pagination,
  search
}: DataTableProps<TData, TValue>) {


  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })
  return (
    <div className="space-y-4">
      <PassageTableToolbar table={table} search={search} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const passage = row.original as Passage;
                return (
                  <TableRow
                    id={`table-row-${row.id}`}
                    style={{ cursor: row.getValue('status') === 'completed' ? "pointer" : "default" }}
                    onClick={(e: SyntheticEvent) => {
                      if (window.clickedDelete === true) {
                        return;
                      } else {
                        if (row.getValue('status') === 'completed') {
                          window.location.href = `/passages/${passage.id}`
                        } else {
                          return;
                        }
                      }
                    }}
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {pagination}
    </div>
  )
}

export default function PassageTable() {
  const [data, setData] = useState<Passage[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    const getPassages = async () => {
      try {
        const response = await client.getPassages()
        if (response.status === 200) {
          if (response.data.offset === 0) {
            setPage(1)
          } else {
            setPage(response.data.offset / 10)
          }
          setOffset(response.data.offset)
          setCount(response.data.count)
          setData(response.data.passages)
        }
      } catch (e) {
        console.error(e)
      }
    }
    getPassages()
  }, [])

  const handleNextPageClicked = async () => {
    const newPage = page + 1
    const newOffset = newPage * 10;
    try {
      const response = await client.getPassages(newOffset)
      if (response.status === 200) {
        if (response.data.offset === 0) {
          setPage(1)
        } else {
          setPage(response.data.offset / 10)
        }
        setOffset(response.data.offset)
        setCount(response.data.count)
        setData(response.data.passages)
      }
    } catch (e) {
      console.error(e)
    }
    
  }

  const handlePreviousPageClicked = async () => {
    const newPage = page === 0 ? 1 : page - 1
    const newOffset = newPage * 10;
    try {
      const response = await client.getPassages(newOffset)
      if (response.status === 200) {
        if (response.data.offset === 0) {
          setPage(1)
        } else {
          setPage(response.data.offset / 10)
        }
        setOffset(response.data.offset)
        setCount(response.data.count)
        setData(response.data.passages)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleSearch = async (searchTerm: string) => {
    try {
      const response = await client.getPassages(0, searchTerm)
      if (response.status === 200) {
        if (response.data.offset === 0) {
          setPage(1)
        } else {
          setPage(response.data.offset / 10)
        }
        setOffset(response.data.offset)
        setCount(response.data.count)
        setData(response.data.passages)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const pagination = (
    <div className="flex items-center justify-end space-x-2 py-1">
      <Button variant="outline" onClick={handlePreviousPageClicked} disabled={offset==0}>Previous</Button>
      <Button onClick={handleNextPageClicked}variant="outline" disabled={offset >= count}>Next</Button>
    </div>
  )

  return (
    <div className="w-full py-10">
      <DataTable columns={columns} data={data} pagination={pagination} search={handleSearch} />
    </div>
  )
}