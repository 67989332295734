import React, { useState, useEffect } from 'react';
import { cn } from "../lib/utils"
import { buttonVariants } from "../components/ui/button"
import { UserAuthForm } from "../components/user-auth-form"
import { Icons } from '../components/icons';

export default function AuthenticationPage() {
  return (
    <>
      <div className="w-full h-[1000px]">
        <div className="h-full flex flex-col items-center justify-center">
          <div className="relative z-20 flex items-center justify-center space-y-2">
            <Icons.DEPAGE_LOGO className="h-[80px] w-[80px] mr-2" />
          </div>
          <UserAuthForm />
          <p className="px-8 text-center text-sm text-muted-foreground py-3">
            By clicking continue, you agree to our{" "}
            <a
              href="https://depage.io/terms"
              target="_blank"
              className="underline underline-offset-4 hover:text-primary"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://depage.io/privacy"
              target="_blank"
              className="underline underline-offset-4 hover:text-primary"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </>
  )
}