import axios from 'axios'
import qs from 'qs'

const headers: any = {
  'Content-Type': 'application/json'
}

let authToken: string | null = null;
if (typeof localStorage !== 'undefined') {
  authToken = localStorage.getItem('jwt_access_token')
}

if (!!authToken) {
  headers['Authorization-Token'] = authToken
}

const v1 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
})

let client = {
  // returns the search summary and the first page of examples. use the retrieve examples call to get subsequent pages.
  getCurrentUser: () => v1.get('/users/me').then(res => res),
  createCredential: (phoneNumber: string) => v1.post('/auth_verification_code', { phone_number: phoneNumber }).then(res => res),
  verifyCredential: (phoneNumber: string, verificationCode: string) => v1.post(`/auth`, { phone_number: phoneNumber, verification_code: verificationCode }).then(res => res),
  getPassages: (page?: number, searchTerm?: string) => {
    if (!!page) {
      return v1.get(`/passages?offset=${page}`).then(res => res)
    }

    if (!!searchTerm) {
      return v1.get(`/passages?search=${searchTerm}`).then(res => res)
    }
    
    return v1.get('/passages').then(res => res)
  },
  getPassage: (passageId: string) => v1.get(`/passages/${passageId}`).then(res => res), 
  deletePassage: (passageId: string) => v1.delete(`/passages/${passageId}`).then(res => res),
  createUpload: (fileType: string) => v1.post('/uploads', { upload: { file_type: fileType }}).then(res => res),
  createPassage: (
    presignedUrlId: string, 
    passageName: string, 
    startingPage: string, 
    endingPage: string
  ) => v1.post('/passages', { presigned_url_id: presignedUrlId, name: passageName, starting_page: startingPage, ending_page: endingPage }).then(res => res),
  getRawText: (passageId: string) => v1.get(`/passages/${passageId}/raw_text`).then(res => res),
  getFile: (passageId: string) => v1.get(`/passages/${passageId}/file`).then(res => res),
  getMessages: (passageId: string, after?: string) => { 
    let params: any = {}
    if (!!after) {
      return v1.get(`/passages/${passageId}/messages?after=${after}`).then(res => res)
    } else {
      return v1.get(`/passages/${passageId}/messages`).then(res => res)
    }
  },
  sendMessage: (passageId: string, text: string) => v1.post(`/passages/${passageId}/messages`, { message: { text: text }}).then(res => res),
}

export default client;
