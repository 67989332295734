import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter
} from "./ui/dialog"
import { Label } from './ui/label';
import { Input } from './ui/input';
import { Button } from "./ui/button"
import { FileUploader } from "react-drag-drop-files";
import client from "../client"

export default function CreatePassageDialog({ children  }: { children: React.ReactNode }) {
  const [passageName, setPassageName] = useState<string>("")
  const [startingPage, setStartingPage] = useState<string>("")
  const [endingPage, setEndingPage] = useState<string>("")
  const [file, setFile] = useState<File | null>(null)

  async function submitForm(event: React.SyntheticEvent) {
    event.preventDefault()
    console.log("submitting form")
    try {
      const fileType = file?.type ?? ""
      const upload = await client.createUpload(fileType)
      const presignedUrl = upload.data;
      const newPassage = await client.createPassage(presignedUrl.id, passageName, startingPage, endingPage)

      const formData = new FormData();
      
      const keys = Object.keys(presignedUrl)
      keys.forEach((key) => {
        if (key === "id" || key === "url") {
          return
        }
        formData.append(key, presignedUrl[key])
      })

      formData.append("file", file as File)

      const response = await fetch(presignedUrl.url, {
        method: "POST",
        body: formData
      });

      if (!response.ok) {
        throw new Error("Failed to upload file")
      } else {
        window.location.href = '/'
      }
      
      // multipart-form upload
    } catch (e) {
      console.error(e)
    }
  }

  const handleFileChange = (file: File) => {
    setFile(file)
  }

  const fileTypes = ["PDF"]

  return (
    <Dialog>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Add a new Passage</DialogTitle>
          <DialogDescription>
            Add a new passage using the form below.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={submitForm}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name">
                Passage Name
              </Label>
              <Input id="name" value={passageName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassageName(e.target.value)} className="col-span-3" />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="file">
                File
              </Label>
              <FileUploader multiple={false} handleChange={handleFileChange} name="file" types={fileTypes} />
            </div>
          </div>
          <DialogFooter>
        <Button type="submit">Save</Button>
      </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}