import React, { useEffect, useState } from 'react';
import { Table } from "@tanstack/react-table"
import { Button } from "./ui/button"
import { Input } from './ui/input';
import { PlusIcon } from '@radix-ui/react-icons';
import CreatePassageDialog from './createPassageDialog';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  search: (searchTerm: string) => void
}

export function PassageTableToolbar<TData>({
  table,
  search
}: DataTableToolbarProps<TData>) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Search Passages..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) => {
            table.getColumn("name")?.setFilterValue(event.target.value)
            search(event.target.value)    
          }
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
      </div>
      <CreatePassageDialog>
        <Button variant="default" className="h-8">
          <PlusIcon /><span className="ml-1 font-bold">Create Passage</span>
        </Button>
      </CreatePassageDialog>
    </div>
  )
}