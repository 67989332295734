import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Progress } from "../components/ui/progress"
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../components/ui/tabs"
import { ChatBubbleIcon, MagicWandIcon } from '@radix-ui/react-icons';
import MarkdownView from 'react-showdown';
import client from '../client'
import { Document, Page } from 'react-pdf';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet"
import { Button } from '../components/ui/button';
import PassageChat from '../components/passageChat';



export default function Passage() {
  const loaderData = useLoaderData()

  const [isLoading, setIsLoading] = useState(true)
  const [passage, setPassage] = useState(null)
  const [progressValue, setProgressValue] = useState(0)
  const [rawText, setRawText] = useState(null)
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    if (loaderData && loaderData.passage) {
      setPassage(loaderData.passage.data)
      // wait 1 second to hide the loading screen
      setTimeout(() => {
        setProgressValue(100)
      }, 1000)

      setTimeout(() => {
        setIsLoading(false)
      }, 1500)

      const fetchRawText = async () => {
        try {
          const rawText = await client.getRawText(loaderData.passage.data.id)
          setRawText(rawText)
        } catch (err) {
          console.error(err)
        }
      }
      fetchRawText()
    }
  }, [loaderData])

  // Add this useEffect hook
  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setProgressValue(oldValue => Math.min(100, oldValue + 1));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="flex h-screen bg-gray-100">
      { isLoading ? (
        <div className="flex-1 flex flex-col items-center">
          <Progress value={progressValue} />
        </div>
      ) : (
        <>
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="flex-1 flex overflow-hidden">
              <main className="flex-1 overflow-y-auto">
                <div className="p-6 container mx-auto">
                  <div className="flex items-center justify-between space-y-2">
                    <div>
                      <h2 className="text-2xl font-bold tracking-tight">{passage.name}</h2>
                        <p className="text-muted-foreground pt-2">
                          Pages {passage.starting_page} - {passage.ending_page}
                        </p>
                    </div>
                  </div>
                </div>
                <div className="container mx-auto px-4">
                  <Tabs defaultValue="Summary" className="space-y-4">
                    <TabsList>
                      <TabsTrigger value="Summary">Summary</TabsTrigger>
                    </TabsList>
                    <TabsContent value="Summary" className="space-y-4">
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/2 ">
                          <div className="flex flex-col h-full">
                            <div className="flex-1 overflow-y-auto">
                              <div className="bg-white rounded-lg shadow-sm p-6">
                                <h3 className="text-xl flex items-center font-semibold mb-4">
                                  <MagicWandIcon className="mr-2" /> <span>AI Generated Summary </span>
                                </h3>
                                <p className="text-muted-foreground mt-4">
                                  <MarkdownView
                                    markdown={passage.summary}
                                    options={{ tables: true, emoji: true }}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </TabsContent>
                  </Tabs>
                </div>
              </main>
            </div>
          </div>
          <div className="w-80 bg-white p-4 border-l">
            <div className="flex flex-col h-full">
              <div className="flex-1 overflow-y-auto">
                <Sheet>
                  <SheetTrigger>
                    <Button variant="ghost">
                      <ChatBubbleIcon />
                    </Button>
                  </SheetTrigger>
                  <SheetContent>
                    <PassageChat passageId={passage.id} />
                  </SheetContent>
                </Sheet>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}