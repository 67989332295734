import * as React from "react"

import { cn } from "../lib/utils"
import { Icons } from "./icons"
import { Button } from "./ui/button"
import PhoneInput from 'react-phone-number-input/input'
import { Label } from './ui/label';
import { Input } from './ui/input'; 

import E164Number from 'react-phone-number-input';
import client from "../client"
import { useCookies } from 'react-cookie';

import { usePostHog } from 'posthog-js/react'

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const posthog = usePostHog();
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>()
  const [step, setStep] = React.useState<number>(1)
  const [verificationCode, setVerificationCode] = React.useState<string>("")
  const [cookies, setCookie, removeCookie] = useCookies(['Authorization-Token'])

  async function onSubmitStep1(event: React.SyntheticEvent) {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await client.createCredential(phoneNumber!)
      if (response.status === 201) {
        setIsLoading(false)
        setStep(2)
      }

    } catch (error) {
      console.error(error)
    }
  }

  async function onSubmitStep2(event: React.SyntheticEvent) {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await client.verifyCredential(phoneNumber!, verificationCode!)
      if (response.status === 201) {
        const { data } = response;
        localStorage.setItem("jwt_access_token", data.session_token)
        setCookie("Authorization-Token", data.session_token, { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN })
        posthog?.identify(data.user_id, {})
        setIsLoading(false)
        window.location.href = "/"
      }
    } catch(error) {
      console.error(error)
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <>
      <div className="flex flex-col space-y-2 text-center">
        <h3 className="text-2xl font-semibold tracking-tight">
          {step === 1 ? (
            "Enter your phone number to sign in or create an account"
          ) : "Enter your verification code " }
        </h3>
      </div>
        {step === 1 && (
          <form onSubmit={onSubmitStep1}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <PhoneInput 
                  country="US"
                  placeholder="Enter your phone number"
                  addInternationalOption={false}
                  international={false}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                />
              </div>
              <Button disabled={isLoading}>
                {isLoading && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                Get Started
              </Button>
            </div>
          </form>
        )}
      </>
      <>
        {step === 2 && (
          <form onSubmit={onSubmitStep2}>
            <div className="grid gap-2">
              
              <div className="grid gap-1">
                <Label className="sr-only" htmlFor="email">
                  Verification Code
                </Label>
                <Input 
                  type="string"
                  placeholder="Enter your verification code"
                  value={verificationCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVerificationCode(e.target.value)}
                />
              </div>
              <Button disabled={isLoading}>
                {isLoading && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                Get Started
              </Button>
            </div>
          </form>
        )}
      </>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
      </div>
    </div>
  )
}